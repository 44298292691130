import * as React from "react"
import { Link } from "gatsby"
import type { HeadFC } from "gatsby"


const IndexPage = () => {
  return (
    <main>
      <h1>welcome to the home page</h1>
      <h2>
        <Link to="/moody">go to moody</Link>
      </h2>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
